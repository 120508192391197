import { Route, Routes, useLocation } from "react-router-dom"
import { useEffect, useRef, useState } from "react";

import Header from "./globalComponents/header";
import Home from "./home/home";
import Midaas from "./midaas/midaas";
import Intellicharge from "./intellicharge/intellicharge";
import NewIntellicharge from "./intellicharge/newIntellicharge";
import Dup from "./intellicharge/dup";
import ContactUs from "./smallerPages/contactUs";
import SecurEV from "./securEv/securEv";
import News from "./smallerPages/news";
import Recognition from "./smallerPages/recognition";
import AboutUs from "./smallerPages/aboutUs";
import Careers from "./smallerPages/careers";
import Privacy from "./smallerPages/privacy";
import GoogleAnalytics from "./GoogleAnalytics";
import MidaasPrivacy from "./smallerPages/midaasPrivacy";

function App() {
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [headerBg, setHeaderBg] = useState("top");
  const [animationType, setAnimationType] = useState("down");

  const location = useLocation();
  const scrollRef = useRef();

  const animationClass = {
      up: "slideUp",
      down: "slideDown",
  };

  const topLocations = ["/", "/intellicharge", "/midaas", "/aboutus", "/recognition", "/careers", "/securev"];

  // Scroll handling logic
  const handleScroll = () => {
      if (scrollRef.current) {
          const scrollTop = scrollRef.current.scrollTop;
          if (currentScrollPosition < scrollTop && scrollTop > 200) {
              setAnimationType("up");
            //   console.log("up")
          } else {
              setAnimationType("down");
              setHeaderBg(scrollTop === 0 ? "top" : "up");
          }
          setCurrentScrollPosition(scrollTop);
      }
  };

  useEffect(() => {
      if (scrollRef.current) {
          scrollRef.current.addEventListener("scroll", handleScroll);
      }
      return () => {
          if (scrollRef.current) {
              scrollRef.current.removeEventListener("scroll", handleScroll);
          }
      };
  }, []); // Only attach listener once


  // Auto-scroll logic
  useEffect(() => {
      if (location.pathname === "/contact" && scrollRef.current) {
          scrollRef.current.scrollTo({
              top: scrollRef.current.scrollHeight - 288,
              behavior: "smooth",
          });
      } else if (topLocations.includes(location.pathname) && scrollRef.current) {
          scrollRef.current.scrollTop = 0;
      }
  }, [location]);

  return (
      <>
          <div className={`fixed top-0 left-0 w-full z-[9999] ${animationClass[animationType]}`}>
              <Header headerBg={headerBg} animationType={animationType} />
          </div>
          <div ref={scrollRef} className="w-[100vw] h-[100vh] z-[50] bg-baseWhite overflow-y-scroll overflow-x-hidden">
              <GoogleAnalytics />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/midaas" element={<Midaas />} />
                  <Route path="/intellicharge" element={<NewIntellicharge />} />
                  <Route path="/oldIntellicharge" element={<Intellicharge />} />
                  <Route path="/securev" element={<SecurEV />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/recognition" element={<Recognition />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/careers" element={<Careers />} />
                  {/* <Route path="/privacy" element={<Privacy />} /> */}
                  <Route path="/intellicharge-app/privacy" element={<Privacy />} />
                  <Route path="/midaas/termsandconditions" element={<MidaasPrivacy />} />
                  {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
          </div>
      </>
  );
}

export default App;

