import { useState, useEffect, useRef } from "react"
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function AboutUs() {

    const [splideOptions, setSplideOptions] = useState({
        type: 'loop', // Ensure loop type
        width: '100%',
        height: 230,
        drag: 'free',
        gap: "1rem",
        perPage: 5,
        perMove: 1,
        pagination: false,
        autoScroll: {
            speed: 1.2,
            pauseOnHover: false, // Prevent pause on hover
            pauseOnFocus: false, // Prevent pause on focus 
        },
    });

    const splideRef = useRef()
    const splideParentDiv = useRef(null);
    const widthRef = useRef(null);
    const perPageRef = useRef(null);

    const goNext = () => splideRef.current.splide.go("+");
    const goPrev = () => splideRef.current.splide.go("-");

    const logoUrls = ['1200px-NSF_logo.png', 'DOEStamp.png', 'pacificorp-removebg-preview.png', 'heberLightAndPowerLogo.png', 'utahCleanCitiesLogo.png', 'leadersForCleanAirLogo.png', 'u_of_u.png', 'usmart_logo.png', 'UCAIR_Logo.png']

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (window.innerWidth >= 2400) {
    //             setSplideOptions(options => ({ ...options, perPage: 9 }));
    //         } else if (window.innerWidth >= 2000) {
    //             setSplideOptions(options => ({ ...options, perPage: 8 }));
    //         } else if (window.innerWidth >= 1600) {
    //             setSplideOptions(options => ({ ...options, perPage: 7 }));
    //         } else if (window.innerWidth >= 1400) {
    //             setSplideOptions(options => ({ ...options, perPage: 6 }));
    //         } else if (window.innerWidth >= 1200) {
    //             setSplideOptions(options => ({ ...options, perPage: 5 }));
    //         } else if (window.innerWidth >= 1000) {
    //             setSplideOptions(options => ({ ...options, perPage: 4 }));
    //         } else if (window.innerWidth >= 800) {
    //             setSplideOptions(options => ({ ...options, perPage: 3 }));
    //         } else {
    //             setSplideOptions(options => ({ ...options, perPage: 2 }));
    //         }
    //     }, 300)
    // }, [])

    useEffect(() => {
        const handleResize = () => {
            if (splideParentDiv.current) {
                const newWidth = splideParentDiv.current.offsetWidth;
                let newPerPage = 2;

                if (newWidth >= 2200) {
                    newPerPage = 9;
                } else if (newWidth >= 1800) {
                    newPerPage = 8;
                } else if (newWidth >= 1400) {
                    newPerPage = 7;
                } else if (newWidth >= 1200) {
                    newPerPage = 6;
                } else if (newWidth >= 1000) {
                    newPerPage = 5;
                } else if (newWidth >= 800) {
                    newPerPage = 4;
                } else if (newWidth >= 600) {
                    newPerPage = 3;
                }

                if (widthRef.current !== newWidth || perPageRef.current !== newPerPage) {
                    widthRef.current = newWidth;
                    perPageRef.current = newPerPage;
                    setSplideOptions(options => ({
                        ...options,
                        // width: newWidth,
                        perPage: newPerPage,
                    }));
                }
            }
        };

        if (splideParentDiv.current) {
            handleResize();
        }

        const resizeObserver = new ResizeObserver(handleResize);
        if (splideParentDiv.current) {
            resizeObserver.observe(splideParentDiv.current);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            if (splideParentDiv.current) {
                resizeObserver.unobserve(splideParentDiv.current);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [splideParentDiv]);


    return (
        <div className="w-full h-full bg-baseWhite">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"powerline_clip.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div className="">
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">About Us</h2>
                    </div>
                </div>
            </div>
            <div className="bg-baseWhite pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <p className="text- text-xl laptop:text-2xl font-ralewayReg">Grid Elevated solves the juxtaposition between the last-generation grid and the next generation of needs, by creating AI-powered solutions that cater the intelligence, resilience and security needs for grid transformation. A proud Utah-based company made up of world-renowned researchers, innovators, energy experts, and advocates, Grid Elevated creates the technological solutions to make today’s power grid more intelligent, resilient, and secure for tomorrow.</p>
                <h3 className="text- text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Mission</h3>
                <p className="w-full mt-2  text-lg font-ralewayReg" >Grid Elevated's mission is to create innovative technology solutions that help communities, industries, and utilities to integrate clean, affordable, and resilient electricity services. We rely on our established research prowess and combine it with our vigor to commercialize our cutting-edge technologies to produce innovative and game-changing products.</p>
                <h3 className="text- text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Team</h3>
                <div className="w-full grid grid-cols-10 gap-6 mt-3">
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-md flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-center object-cover rounded-tl-md rounded-bl-md" src="/images/Masood.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Masood Parvania</h2>
                            <h3 className="p-2 pt-2 text-sm ">Founder and President</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Jairo.png" alt="Jairo Giraldo"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Jairo Giraldo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Manager, Product Development</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Alex.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Alex Farley</h2>
                            <h3 className="p-2 pt-2 text-sm ">Lead Software Developer</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Carter.jpg" alt="Carter Johnson"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Carter Johnson</h2>
                            <h3 className="p-2 pt-2 text-sm ">Front-End Software Developer</h3>
                        </div>
                    </div>
                    {/* <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Tara.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Tara Keene</h2>
                            <h3 className="p-2 pt-2 text-sm ">Back-End Software Developer</h3>
                        </div>
                    </div> */}
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Adrian.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Adrian Sucahyo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Embedded Systems Engineer</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/drew.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Drew Humphrey</h2>
                            <h3 className="p-2 pt-2 text-sm ">Software Development Intern</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/CynthiaYeo.png.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Cynthia Yeo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Marketing and Content Architect</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/AnnaCopeland.png.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Anna Copeland-Rynders</h2>
                            <h3 className="p-2 pt-2 text-sm ">Graphic Designer</h3>
                        </div>
                    </div>
                </div>
                <h3 className="text-black text-4xl font-semibold mt-32 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Partners</h3>
                <div ref={splideParentDiv} className="-ml-24 -mr-24 h-56 ">
                    <Splide
                        ref={splideRef}
                        options={splideOptions}
                        hasTrack={false}
                        extensions={{ AutoScroll }}
                    >
                        <SplideTrack>
                            {logoUrls.map((logo, index) => (
                                <SplideSlide key={index}>
                                    <div className="w-full h-full flex items-center justify-center p-6 laptop:p-8 mr-6 ">
                                        <img src={`/images/${logo}`}></img>
                                    </div>
                                </SplideSlide>
                            ))}
                        </SplideTrack>
                        <div className="splide__arrows hidden"></div>
                    </Splide>
                </div>
                <div className="w-full grid grid-cols-12 gap-6 mt-24">
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Large group photo of the Grid Elevated team and their sponsors."
                            src="/images/GroupPhoto.jpeg"
                            className="object-cover w-full"
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Group photo of some members of the Grid Elevated team in front of a Tesla."
                            src="/images/GrandAmericaGroupPicTesla.jpg"
                            className="object-cover w-full"
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Group photo of some members of the Grid Elevated team in front of a Tesla."
                            src="/images/GridElevatedGroupPhotoStand.jpeg"
                            className="object-cover w-full"
                        />
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default AboutUs